export default {
  mapBoxAccessToken: 'pk.eyJ1IjoiYWxpemFuZGQiLCJhIjoiY2tlbzI0cWRxMjNqdzJzcGN0NXpvbDhieiJ9.UUMfcAPNPe2wHEdkYMDEeQ',
  mapbox: {
    container: 'mapContainer',
    style: 'mapbox://styles/alizandd/cku49jpw80q8z18p9yb3yjt6a',
    attributionControl: false,
    zoom: 10,
    pitch: 60,
    bearing: -17.6
  }
}
