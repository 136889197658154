import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'
import './util/main.css'
import './assets/styles/_global.scss'

import mitt from 'mitt'

// Mapbox
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

// Environment variables
import environment from './environment'
const emitter = mitt()

mapboxgl.accessToken = environment.mapBoxAccessToken

const app = createApp(App)

app.config.globalProperties.emitter = emitter

app.use(createPinia())
app.use(router)

app.mount('#app')
